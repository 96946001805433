import './setup.css'
import DeleteIcon from '@material-ui/icons/Delete';
import React from "react";
import SaveIcon from '@material-ui/icons/Save';
import {Box, Button, Container, Grid, Paper, TextField, Typography} from "@material-ui/core"
import {CookieName} from "../constants";
import {Redirect} from 'react-router-dom'
import {checkApiKeyPermission} from "../api/recentlyViewedApiRequests";

interface Props {
}


interface State {
    redirect: string,
    error: boolean
}

export class Setup extends React.Component<Props, State> {
    private apiKey: string = localStorage.getItem(CookieName.credential) || "";
    private storeId: string = localStorage.getItem(CookieName.storeId) || "";
    private salesId: string = localStorage.getItem(CookieName.salesId) || "";

    constructor(props: object) {
        super(props);
        this.state = {redirect: '', error: false};
        this.saveConfig = this.saveConfig.bind(this);
    }

    saveConfig() {
        this.storeId && localStorage.setItem(CookieName.storeId, this.storeId);
        this.salesId && localStorage.setItem(CookieName.salesId, this.salesId);
        if (this.apiKey && this.apiKey.length === 32) {
            checkApiKeyPermission(this.apiKey).then((response) => {
                localStorage.setItem(CookieName.credential, this.apiKey);
                this.setState({error: false});
                this.setState({redirect: '../'})
            }).catch((reason => {
                this.setState({error: true});
            }))
        } else {
            this.setState({error: true});
        }
    }

    render() {
        if (this.state.redirect !== '') {
            return <Redirect to={this.state.redirect}/>;
        } else {
            return (
                <Box py={8}>
                    <Container maxWidth="sm">
                        <Paper elevation={3}>
                            <Box p={2}>
                                <form noValidate autoComplete="off">
                                    <Box m={2}><Typography align="center" variant="h5">Settings</Typography></Box>
                                    <Box m={2}>
                                        {this.state.error ?
                                            <TextField
                                                required
                                                error
                                                helperText="Invalid Access Key"
                                                id="api-key"
                                                label="Access Key"
                                                variant="outlined"
                                                fullWidth={true}
                                                defaultValue={this.apiKey}
                                                onChange={event => this.apiKey = event.target.value}
                                            /> :
                                            <TextField
                                                required
                                                id="api-key"
                                                label="Access Key"
                                                variant="outlined"
                                                fullWidth={true}
                                                defaultValue={this.apiKey}
                                                onChange={event => this.apiKey = event.target.value}
                                            />
                                        }
                                    </Box>
                                    <Box m={2}>
                                        <TextField
                                            id="store-id"
                                            label="Store ID"
                                            variant="outlined"
                                            fullWidth={true}
                                            defaultValue={this.storeId}
                                            onChange={event => this.storeId = event.target.value}
                                        />
                                    </Box>
                                    <Box m={2}>
                                        <TextField
                                            id="sales-id"
                                            label="Sales ID"
                                            variant="outlined"
                                            fullWidth={true}
                                            defaultValue={this.salesId}
                                            onChange={event => this.salesId = event.target.value}
                                        />
                                    </Box>
                                    <Box m={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Button
                                                    className="config-button"
                                                    variant="contained"
                                                    color="default"
                                                    size="medium"
                                                    fullWidth={true}
                                                    startIcon={<DeleteIcon/>}
                                                    onClick={() => this.setState({redirect: '../'})}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    className="config-button"
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    fullWidth={true}
                                                    startIcon={<SaveIcon/>}
                                                    onClick={this.saveConfig}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </form>
                            </Box>
                        </Paper>
                    </Container>
                </Box>
            );
        }
    }
}
