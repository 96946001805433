import axios, {AxiosResponse} from "axios";
import {ApiEnv, ApiEnvHost, DefaultHost} from "../constants";
import {recentlyViewedApiModelTypes} from "./recentlyViewedApiModelTypes";

const API_ENV = process.env.REACT_APP_API_ENV || ApiEnv.pro;
const API_HOST = ApiEnvHost.get(API_ENV) || DefaultHost;

const client = axios.create({
    timeout: 10000,
    validateStatus: function (status) {
        return status === 200;
    }
});

export function collectQrCodeScanEvent(
    event: recentlyViewedApiModelTypes.requestModels.QrCodeScanEvent,
    apiKey: string
): Promise<AxiosResponse<recentlyViewedApiModelTypes.responseModels.Response>> {
    return client.post(`${API_HOST}/v1/instore/qr-scan`, event, {headers: {'x-api-key': apiKey}})
}

export function checkApiKeyPermission(apiKey: string): Promise<AxiosResponse<recentlyViewedApiModelTypes.responseModels.Response>> {
    return client.post(`${API_HOST}/v1/instore/check-permission`, {}, {headers: {'x-api-key': apiKey}})
}
