import './home.css'
import Fab from '@material-ui/core/Fab';
import QrReader from 'react-qr-reader';
import React from "react";
import SettingsIcon from '@material-ui/icons/Settings';
import queryString from 'query-string'
import {Box, Container, Paper} from "@material-ui/core"
import {CookieName} from "../constants";
import {Redirect} from "react-router-dom"

interface Props {
}

interface State {
    redirect: string
}

const uuidRegex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

export class Home extends React.Component<Props, State> {
    constructor(props: object) {
        super(props);
        this.state = {
            redirect: ''
        }
        this.isSetup = this.isSetup.bind(this)
        this.onError = this.onError.bind(this)
        this.onScan = this.onScan.bind(this)
    }

    isSetup() {
        const api_key = localStorage.getItem(CookieName.credential);
        return (api_key != null) && (api_key.length === 32);
    }

    onError(err: any) {
        if (err) {
            console.log(err);
        }
    }

    onScan(data: string | null) {
        if (data !== null) {
            console.log(data);
            let match = data.match(uuidRegex);
            if (match) {
                let query = queryString.stringify({uuid: data});
                this.setState({redirect: `./collect?${query}`});
            }
        }
    }


    render() {
        if (this.state.redirect !== '') {
            return (<Redirect to={this.state.redirect}/>);
        } else if (!this.isSetup()) {
            return (<Redirect to='./setup'/>);
        }
        return (
            <Box py={6}>
                <Container maxWidth="md">
                    <Paper elevation={3}>
                        <Box p={2}>
                            <QrReader delay={300} onError={this.onError} onScan={this.onScan} showViewFinder={false}/>
                        </Box>
                    </Paper>
                </Container>
                <Box p={2} className="setting-button">
                    <Fab aria-label="settings" onClick={() => this.setState({redirect: './setup'})}>
                        <SettingsIcon/>
                    </Fab>
                </Box>
            </Box>
        );
    }
}
