import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Collect} from "./components/collect";
import {Home} from "./components/home"
import {Setup} from "./components/setup"

class App extends React.Component {
    render() {
        const basename = process.env.PUBLIC_URL || '';
        return (
            <Router basename={basename}>
                <Switch>
                    <Route exact path="/"><Home/></Route>
                    <Route exact path="/setup"><Setup/></Route>
                    <Route exact path="/collect" component={Collect}/>
                    <Route path="/*"><h1>404</h1></Route>
                </Switch>
            </Router>
        );
    }
}


export default App;
