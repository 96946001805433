import * as RecentlyViewedApiRequests from '../api/recentlyViewedApiRequests';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import React from "react";
import queryString from 'query-string'
import {Box, Button, Container, Grow, Paper, Typography} from "@material-ui/core";
import {CookieName, ErrorMessages} from "../constants"
import {Redirect} from "react-router-dom";


interface Location {
    search: string
}

interface Props {
    location: Location
}

interface State {
    redirect: string,
    saved: boolean,
    status: number
}


export class Collect extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            redirect: "",
            saved: false,
            status: 0
        };
    }

    componentDidMount() {
        let {uuid = ''} = queryString.parse(this.props.location.search);
        if (uuid === null || Array.isArray(uuid)) {
            // todo: display error
            return this.setState({redirect: '../'})
        }
        let apiKey = localStorage.getItem(CookieName.credential);
        if (!apiKey) {
            return this.setState({redirect: '../setup'})
        }
        let storeId = localStorage.getItem(CookieName.storeId) || "";
        let salesId = localStorage.getItem(CookieName.salesId) || "";

        RecentlyViewedApiRequests.collectQrCodeScanEvent(
            {
                uuid: uuid,
                storeId: storeId,
                salesId: salesId
            },
            apiKey
        ).then((response) => {
            this.setState({saved: true, status: 200});
        }).catch((reason) => {
            if (reason.code === 'ECONNABORTED') {
                this.setState({status: -1})
            } else {
                this.setState({status: reason.response.status});
            }
        })
    }

    render() {
        if (this.state.redirect !== '') {
            return (<Redirect to='../'/>);
        }

        let icon = <CheckCircleOutlineIcon style={{fontSize: 80}}/>;
        if (this.state.status !== 0 && this.state.status !== 200) {
            icon = <ErrorIcon style={{fontSize: 80}}/>
        }

        return (
            <Container maxWidth="sm">
                <Box m={5}>
                    <Paper elevation={3}>
                        <div>
                            <Box p={5}>
                                <Typography align="center" variant="h2">Thank you!</Typography>
                            </Box>
                        </div>
                        <div>
                            <Box p={5}>
                                <Typography align="center">
                                    <Grow in={this.state.status !== 0} timeout={500}>
                                        {icon}
                                    </Grow>
                                </Typography>
                            </Box>
                            <Typography align="center">
                                {ErrorMessages.get(this.state.status)}
                            </Typography>
                            <Box p={5}>
                                <Typography align="center">
                                    <Button
                                        onClick={() => {
                                            this.setState({redirect: '../'})
                                        }}
                                        startIcon={<ArrowBackIcon/>}
                                        variant="outlined"
                                        size="large">
                                        Go Back
                                    </Button>
                                </Typography>
                            </Box>
                        </div>
                    </Paper>
                </Box>
            </Container>
        );
    }
}
